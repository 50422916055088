<template>
  <Layout>
    <a-spin :spinning="spinning">
      <Design />
    </a-spin>
  </Layout>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import Layout from "@/layout/index.vue";
import Design from "@/design/index.vue";

const DecoSvc = require("@/service/decoration");

import loadDataMixin from "@/mixins/loadData";
import loadDiscountMixin from "@/mixins/loadDiscount";

import { upgrade } from "@/utils/toolbar";

export default {
  name: "Home",

  data() {
    return {
      spinning: true
    };
  },

  computed: {
    ...mapState({
      desginData: state => state.design,
      oldDesignData: state => state.oldDesignData
    })
  },

  mixins: [loadDataMixin, loadDiscountMixin],

  methods: {
    ...mapMutations(["setDesignData", "updateOldDesignData"])
  },

  beforeRouteLeave(to, from, next) {
    const { list, fix } = this.desginData;
    let changed = false;
    if (this.oldDesignData) {
      changed = this.oldDesignData !== JSON.stringify({ list, fix });
    } else {
      changed = list.length > 0 || Object.keys(fix).length > 0;
    }

    if (changed) {
      this.$confirm({
        title: "操作提示",
        content: "您的装修内容未发布，确认要离开吗？",

        onOk: () => {
          next();
        },
        onCancel() {
          next(false);
        }
      });
    } else {
      next();
    }
  },

  async mounted() {
    try {
      this.spinning = true;

      const data = await DecoSvc.loadDecorationData();

      let res = "";
      if (data.res) {
        res = JSON.parse(data.res);
      } else {
        res = { list: [], fix: {} };
      }

      // 自动升级数据，兼容旧记录
      upgrade(res);

      this.setDesignData(res);

      // 记录用于判断变更保存提示
      this.updateOldDesignData(res);

      // 监听首页数据保存
      this.$root.$on("home-data-saved", () => {
        const designData = this.$store.state.design;
        this.updateOldDesignData(designData);
      });

      this.spinning = false;
    } catch (err) {
      // 仅当有页面时才有异常
      if (this.$store.state.pageList.length > 0) {
        console.error(err);
        this.$message.error("未正确加载首页数据，如果页面正常请忽略");
      }

      this.spinning = false;
    }
  },

  components: {
    Layout,
    Design
  }
};
</script>
